<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.10574 5.10571C1.30427 8.90718 1.30427 15.0928 5.10574 18.8943C8.90722 22.6958 15.0929 22.6958 18.8943 18.8943C22.6958 15.0928 22.6958 8.90718 18.8943 5.10571C15.0929 1.30424 8.90722 1.30424 5.10574 5.10571ZM15.182 9.87868L13.0607 12L15.182 14.1213C15.3227 14.262 15.4017 14.4527 15.4017 14.6517C15.4017 14.8506 15.3227 15.0413 15.182 15.182C15.0414 15.3226 14.8506 15.4017 14.6517 15.4017C14.4528 15.4017 14.262 15.3226 14.1214 15.182L12 13.0607L9.87871 15.182C9.73806 15.3226 9.5473 15.4017 9.34838 15.4017C9.14947 15.4017 8.95871 15.3226 8.81805 15.182C8.6774 15.0413 8.59838 14.8506 8.59838 14.6517C8.59838 14.4527 8.6774 14.262 8.81805 14.1213L10.9394 12L8.81805 9.87868C8.6774 9.73803 8.59838 9.54727 8.59838 9.34835C8.59838 9.14944 8.6774 8.95867 8.81805 8.81802C8.95871 8.67737 9.14947 8.59835 9.34838 8.59835C9.5473 8.59835 9.73806 8.67737 9.87871 8.81802L12 10.9393L14.1214 8.81802C14.262 8.67737 14.4528 8.59835 14.6517 8.59835C14.8506 8.59835 15.0414 8.67737 15.182 8.81802C15.3227 8.95867 15.4017 9.14944 15.4017 9.34835C15.4017 9.54727 15.3227 9.73803 15.182 9.87868Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
