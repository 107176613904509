<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 24 24">
    <path
      d="M18.3279 22.5C17.4129 22.5 16.1276 22.1691 14.2029 21.0937C11.8624 19.7812 10.0521 18.5695 7.72432 16.2478C5.47995 14.0048 4.38776 12.5526 2.85917 9.77109C1.13229 6.63046 1.42667 4.98421 1.75573 4.28062C2.1476 3.43968 2.72604 2.93671 3.4737 2.43749C3.89836 2.15926 4.34776 1.92075 4.8162 1.72499C4.86307 1.70484 4.90667 1.68562 4.94557 1.66827C5.1776 1.56374 5.52917 1.40577 5.97448 1.57452C6.27167 1.68609 6.53698 1.91437 6.95229 2.32452C7.80401 3.16452 8.96792 5.0353 9.39729 5.95405C9.68557 6.57327 9.87635 6.98202 9.87682 7.44046C9.87682 7.97718 9.60682 8.39109 9.27917 8.8378C9.21776 8.92171 9.15682 9.00187 9.09776 9.07968C8.74104 9.54843 8.66276 9.6839 8.71432 9.92577C8.81885 10.4119 9.59838 11.8589 10.8795 13.1372C12.1606 14.4155 13.5659 15.1458 14.0539 15.2498C14.306 15.3037 14.4443 15.2222 14.9281 14.8528C14.9974 14.7998 15.0687 14.745 15.1432 14.6901C15.6429 14.3184 16.0376 14.0555 16.5617 14.0555H16.5645C17.0206 14.0555 17.411 14.2533 18.0579 14.5795C18.9017 15.0051 20.8287 16.1541 21.6739 17.0067C22.0849 17.4211 22.3142 17.6855 22.4262 17.9822C22.5949 18.4289 22.436 18.7791 22.3324 19.0134C22.3151 19.0523 22.2959 19.095 22.2757 19.1423C22.0784 19.6099 21.8385 20.0584 21.559 20.482C21.0607 21.2273 20.5559 21.8044 19.7131 22.1967C19.2803 22.4014 18.8066 22.5051 18.3279 22.5Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#58595B'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
