<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.97656 8.5C9.97656 9.59163 9.09163 10.4766 8 10.4766C6.90837 10.4766 6.02344 9.59163 6.02344 8.5C6.02344 7.40837 6.90837 6.52344 8 6.52344C9.09163 6.52344 9.97656 7.40837 9.97656 8.5Z"
      stroke-width="0.046875"
    />
    <path
      d="M11.3358 4.35629L11.3354 4.35653L11.3386 4.35987C11.4318 4.4582 11.5538 4.52344 11.6562 4.52344H13.5C13.8915 4.52384 14.2668 4.67954 14.5436 4.95637C14.8205 5.23319 14.9762 5.60853 14.9766 6.00002V12C14.9762 12.3915 14.8205 12.7668 14.5436 13.0436C14.2668 13.3205 13.8915 13.4762 13.5 13.4766H2.50002C2.10853 13.4762 1.7332 13.3205 1.45637 13.0436C1.17954 12.7668 1.02384 12.3915 1.02344 12L1.02344 6.00189C1.02385 5.6104 1.17955 5.23507 1.45637 4.95824C1.7332 4.68142 2.10853 4.52572 2.50002 4.52531H2.625H2.64844V4.50187V4.25187C2.64844 4.12548 2.69865 4.00427 2.78802 3.91489C2.87739 3.82552 2.99861 3.77531 3.125 3.77531H3.875C4.00139 3.77531 4.12261 3.82552 4.21198 3.91489C4.30135 4.00427 4.35156 4.12548 4.35156 4.25187V4.50187V4.52531H4.375C4.43236 4.52531 4.48679 4.50281 4.53482 4.4718C4.58296 4.44072 4.62619 4.40012 4.66165 4.36147L4.66192 4.36172L4.66418 4.35816L5.47481 3.07691L5.47482 3.07689C5.48702 3.05756 5.50064 3.03917 5.51556 3.02187L5.5156 3.02182C5.79145 2.7001 6.16353 2.52344 6.5625 2.52344H9.4375C9.83647 2.52344 10.2086 2.7001 10.4844 3.02182L10.4844 3.02187C10.4994 3.03917 10.513 3.05756 10.5252 3.07689L10.5252 3.07692L11.3358 4.35629ZM11.0234 8.50003V8.5C11.0234 7.90202 10.8461 7.31747 10.5139 6.82027C10.1817 6.32307 9.70948 5.93554 9.15702 5.70671C8.60456 5.47787 7.99665 5.418 7.41016 5.53466C6.82367 5.65132 6.28494 5.93927 5.86211 6.36211C5.43927 6.78494 5.15132 7.32367 5.03466 7.91016C4.918 8.49665 4.97787 9.10456 5.20671 9.65702C5.43554 10.2095 5.82307 10.6817 6.32027 11.0139C6.81747 11.3461 7.40202 11.5234 8 11.5234V11.5L8.00003 11.5234C8.8016 11.5225 9.57009 11.2037 10.1369 10.6369C10.7037 10.0701 11.0225 9.3016 11.0234 8.50003Z"
      stroke-width="0.046875"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#58595B'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
