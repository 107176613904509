<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0735 2.5021C11.743 1.83263 12.8284 1.83263 13.4979 2.5021C14.1674 3.17157 14.1674 4.257 13.4979 4.92647L13.3486 5.07576C13.0557 5.36865 12.5808 5.36865 12.2879 5.07576L10.9242 3.71206C10.6313 3.41916 10.6313 2.94429 10.9242 2.6514L11.0735 2.5021Z"
    />
    <path
      d="M9.9589 4.67739C9.66601 4.3845 9.19113 4.3845 8.89824 4.67739L2.21967 11.356C2.07902 11.4966 2 11.6874 2 11.8863V13.25C2 13.6642 2.33579 14 2.75 14H4.1137C4.31262 14 4.50338 13.921 4.64403 13.7803L11.3226 7.10176C11.6155 6.80887 11.6155 6.33399 11.3226 6.0411L9.9589 4.67739Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
